import { createSelector } from 'reselect';
var defaultMutation = {
  loading: false,
  error: null
};

var getMutationState = function getMutationState(state, type, requestKey) {
  if (requestKey === void 0) {
    requestKey = '';
  }

  return state.requests.mutations[type + requestKey];
};

var mutationSelectors = new WeakMap();

var createMutationSelector = function createMutationSelector(type, requestKey) {
  return createSelector(function (state) {
    return getMutationState(state, type, requestKey);
  }, function (mutationState) {
    return {
      loading: mutationState.pending > 0,
      error: mutationState.error
    };
  });
};

export default (function (state, _ref) {
  var type = _ref.type,
      requestKey = _ref.requestKey;
  var mutationState = getMutationState(state, type, requestKey);

  if (!mutationState) {
    return defaultMutation;
  }

  if (!mutationSelectors.get(mutationState.ref)) {
    mutationSelectors.set(mutationState.ref, createMutationSelector(type, requestKey));
  }

  return mutationSelectors.get(mutationState.ref)(state);
});